import React, { useEffect } from 'react';
import './index.css';

function RoutesApp() {
  useEffect(() => {
    // Redirecionar para o URL externo assim que o componente for montado
    window.location.href = "https://wa.me/+5554991423251?text=Oii!%20Pode%20me%20falar%20mais%20sobre%20esse%20aplicativo%20de%20Inteligência%20Artificial?";
  }, []);

  return (
    <div className='main'>
      <h2>Aguarde que você está sendo redirecionado...</h2>
    </div>
  );
}

export default RoutesApp;
